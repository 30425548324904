import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_DATABASE_API_URL
const USER_URL = `${API_URL}/users`
const GET_USERS_URL = `${API_URL}/users/allUsers`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
   const  all= axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
    console.log('first,',all)
    return all;
}
const getUsersExcel = (query: string): Promise<UsersQueryResponse> => {
   const  all= axios
    .get(`${USER_URL}/allUsersExcel?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
    console.log('first,',all)
    return all;
}

const getCategory = () => {
  const all = axios
    .post(`${USER_URL}/getCategory`)
    //.then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
  //console.log('kategoriler listesi :', all)
  return all
}

const getSubtitlesFilter = (titleId: any) => {
  //console.log('titleId', titleId)

  const all = axios.post(`${USER_URL}/card_list/${titleId}`)
  // //console.log('get modules request subtitles_list',all);

  return all
}


const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers,
   deleteUser,
   deleteSelectedUsers,
   getUserById,
   createUser,
   updateUser,
   getCategory,
   getUsersExcel,
   getSubtitlesFilter}
