import {FC} from 'react'

type Props = {
  status?: number
}

const PayStatus: FC<Props> = ({status}) => (
  <>
  {status == 1 &&<div className='badge badge-light-success fw-bolder'>Bağış Başarılı</div> }
  {status == 2 &&<div className='badge badge-light-danger fw-bolder'>Bağış Başarısız</div> }
  {status == 3 &&<div className='badge badge-light-info fw-bolder'>İşlem Sürecinde</div> }
  {status == 4 &&<div className='badge badge-light-warning fw-bolder'>Hatalı İşlem</div> }
  </>
  
)

export {PayStatus}
