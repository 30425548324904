import {FC} from 'react'

type Props = {
  id?: any
}

const UserId: FC<Props> = ({id}) => (
  <div className='text-muted h3 fw-bolder'>{id}</div>
)

export {UserId}
