import { SetStateAction, useEffect, useMemo, useState } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { initialQueryState, KTIcon, KTSVG, stringifyRequestQuery } from '../../../../../../../_metronic/helpers'
import XLSX from 'sheetjs-style'
//@ts-ignore
import * as FileSaver from 'file-saver'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { getCategory, getSubtitlesFilter, getUsersExcel } from '../../core/_requests'
//@ts-ignore
import DatePicker from "react-datepicker";
//@ts-ignore
import { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr';
import "react-datepicker/dist/react-datepicker.css";
import clsx from 'clsx'
const UsersListFilter = () => {
  const today = new Date();
  // Bir gün öncesine ait tarihi hesapla
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  registerLocale('tr', tr)
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [donateCategoryList, setDonateCategoryList] = useState<any | undefined>()
  const [selectedCard, setSelectedCard] = useState<any | undefined>()
  const [donateId, setDonateId] = useState<string | undefined>()
  const [userName, setUserName] = useState<string | undefined>()
  const [startDate, setStartDate] = useState<any>(yesterday);
  const [endDate, setEndDate] = useState(new Date());
  const [excelVal, setExcelVal] = useState<any>();
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])
  useEffect(() => {
    MenuComponent.reinitialization()
    getDonateCard();
    
  }, [])

useEffect(()=>{
  console.log("query say",query)
},[query])

  const excelGet = async () => {
    
    await getUsersExcel(query).then((data:any) => {
      console.log('daataa',data,query)
      const ws = XLSX.utils.json_to_sheet(data.data);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type:'array' });
      const datas = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(datas, 'customers' + fileExtension);
    })
  }

  // const ExportExcel = ({ excelVal, fileName }: any) => {}
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

  

  const getDonateCard = async (titleSec: any = undefined, subTitleSec: any = undefined) => {
    await getCategory().then((data) => {
      console.log(' gelen değerler kategori', data.data)
      setDonateCategoryList(data.data);
      if (titleSec) {
        TitleOnchange(titleSec, subTitleSec)
      }
    })
  }
  const [subTitleList, setSubTitleList] = useState([]);
  const TitleOnchange = async (titleId: any, subTitleIdSec: any = undefined) => {
    await getSubtitlesFilter(titleId).then(({ data }) => {
      setSubTitleList(data);
      setDonateId(titleId);

      if (subTitleIdSec) {

      }
    })
  }
  var tenYearsAgo =new Date(today.getFullYear() - 10, today.getMonth(), today.getDate())
  const resetData = () => {
    setUserName('');

    setStartDate(tenYearsAgo);
    setEndDate(new Date());
    // setTitleId('');
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    console.log("filterDAta",selectedCard)
    updateState({
      filter: { user_name: userName, donate_category: selectedCard, date_start: (startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()), date_end: endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate() },
      ...initialQueryState,
    })
  }

  // const downloadXLS = () => {
  //   const filter = { user_name: userName, donate_category: selectedCard, date_start: (startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()), date_end: endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate() }
  // }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filtre
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filtre Seçenekleri</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Bağış Kategori:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setSelectedCard(e.target.value)}
              value={donateId}
            >
              <option value={''}>Tümü</option>
              {donateCategoryList?.map((val: any, i: any) => {
                return (
                  <option value={val.product} key={i}>
                    {val.product}
                  </option>)
              })}
              {/* <option value='Administrator'>Administrator</option>
              <option value='Analyst'>Analyst</option>
              <option value='Developer'>Developer</option>
              <option value='Support'>Support</option>
              <option value='Trial'>Trial</option> */}
            </select>
          </div>

          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Tarih Aralığı:</label><br />
            <label>Başlangıç :</label>
            <DatePicker
              todayButton="Bugüne Git"
              dateFormat="dd/MM/yyyy"
              locale={'tr'}
              className='form-control'
              selected={startDate}
              onChange={(date: SetStateAction<Date>) => setStartDate(date)}

              startDate={startDate}
              endDate={endDate}
              shouldCloseOnSelect={false}

            />
            <label>Bitiş :</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              locale={'tr'}
              className='form-control'
              selected={endDate}
              onChange={(date: SetStateAction<Date>) => setEndDate(date)}

              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              shouldCloseOnSelect={false}

            />
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}

          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Resetle
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Getir
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      <button type='button' onClick={(e) =>excelGet()} className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Çıktı Al
      </button>
      {/* end::SubMenu */}
    </>
  )
}

export { UsersListFilter }
