import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
    ListsWidget3,
    ListsWidget4,
    ListsWidget5,
    ListsWidget9,
    MixedWidget6,
    MixedWidget7,
    MixedWidget8,
    StatisticsWidget5,
    TablesWidget11,
    TablesWidget9,
} from '../../../_metronic/partials/widgets'
import UsersPage from '../../modules/apps/user-management/UsersPage'
import { UsersListWrapper } from '../../modules/apps/user-management/users-list/UsersList'

const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      {/* <PageTitle breadcrumbs={[]} description='#XRS-45670'>
        Dashboard
      </PageTitle> */}
      {/* begin::Row */}

      {/* begin::Row */}
      <div className='g-5 gx-xxl-8'>
       
        <UsersListWrapper/>
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Bağışlar</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
