/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {KTIcon} from '../../../helpers'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'

const Toolbar2: FC = () => {
  const {classes, config} = useLayout()
  const today = new Date()
  return (
    <div className='toolbar py-2' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex align-items-center')}
      >
        {config.pageTitle?.display && <DefaultTitle />}

        <div className='flex-grow-1 flex-shrink-0 me-5'></div>

        {/* begin::Actions */}
        <div
          className={
            config.pageTitle?.display
              ? 'd-flex align-items-center flex-wrap'
              : 'd-flex flex-stack flex-grow-1 flex-wrap'
          }
        >
          {/* begin::Wrapper */}
         
          {/* end::Wrapper */}

          {/* begin::Wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Daterangepicker */}
            <span
             
              className='btn btn-sm btn-bg-light btn-color-gray-500 btn-active-color-primary -2'
              id='kt_dashboard_daterangepicker'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              data-bs-trigger='hover'
              title='Select dashboard daterange'
            >
             
              <span className='fw-bolder' id='kt_dashboard_daterangepicker_date'>
                {/* <?php echo date('F j')?> */}
                Bugün: {today.getDate()} {today.toLocaleString('default', {month: 'long'})}
              </span>
            </span>
          
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Toolbar2}
