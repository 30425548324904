import { AuthModel } from './_models'
import { useLang, setLanguage } from '../../../../_metronic/i18n/Metronici18n'
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }
  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }
  try {
    const lsValue = JSON.stringify(auth)

    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }
  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  if (!localStorage.i18nConfig) {
    setLanguage('tr')
  }
  const lang = JSON.parse(localStorage.i18nConfig).selectedLang;
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string, lang: string } }) => {
      const auth = getAuth()
      //const lang = useLang()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }
      config.headers.lang = lang
      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: any) => {
      const originalConfig = response.config;
      if (response.data.code == 1222 || response.data.code == 1221) {
        removeAuth()
        window.location.href = `${process.env.REACT_APP_ANASAYFA}`
      } else if (response.data.code == 1223) {
        setAuth(response.data.cikti);
        return axios(originalConfig);
      }
      return response
    },
    function (err: any) {
     // //console.log("hata", err);
      Promise.reject(err)
    }
  )
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
