import { FC } from 'react'

type Props = {
  donate_date?: any
}

const LuDate: FC<Props> = ({ donate_date }) => {
  const [year, month, day] = donate_date.split('-');
  day.split(' ')
  
  const result = [day.split(' ')[0],month,year, ].join('/');
  return (
    <> {donate_date && <div className=' fw-bolder'>{result+ ' ' +day.split(' ')[1] }</div>}</>
  )
}

export { LuDate }
