import {FC} from 'react'

type Props = {
  error?: string
}

const PayError: FC<Props> = ({error}) => (
  <span className='small'>
  {error}
 
  </span>
  
)

export {PayError}
