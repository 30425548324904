import {FC, useEffect, useState} from 'react'

type Props = {
  phone?: any
}

const UserPhone: FC<Props> = ({phone}) => {
  var newphone = phone.replace(/\D*(\d{4})\D*(\d{3})\D*(\d{2})\D*(\d{2})/, '$1 $2 $3 $4 ');
  return(
  <div className='fw-bolder'>{newphone}</div>
)
}

export {UserPhone}
