import {FC} from 'react'

type Props = {
  total?: string
}

const UserTotal: FC<Props> = ({total}) => (
  <> {total } ₺</>
)

export {UserTotal}
