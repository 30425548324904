// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserPhone} from './UserPhone'
import {UserTotal} from './UserTotal'
import {PayStatus} from './PayStatus'
import {PayError} from './PayError'
import {LuDate} from './LuDate'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import { UserId } from './UserId'

const usersColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <UserCustomHeader  tableProps={props} title='ID' className='min-w-55px ' />,
    id: 'id',
    Cell: ({...props}) => <UserId id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Ad Soyad' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell name={props.data[props.row.index].name} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Ürün' className='min-w-125px' />,
    accessor: 'product',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Bağış Tutarı' className='min-w-125px' />
    ),
    id: 'total',
    Cell: ({...props}) => <UserTotal total={props.data[props.row.index].total} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Bağış Durumu' className='min-w-125px' />
    ),
    Cell: ({...props}) => <PayStatus status={props.data[props.row.index].status} />,
    accessor: 'status',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Telefon' className='min-w-125px' />
    ),
    id: 'phone',
    Cell: ({...props}) => <UserPhone phone={props.data[props.row.index].phone} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Tarih' className='min-w-125px' />
    ),
    id:'date',
    Cell:({...props}) => <LuDate donate_date={props.data[props.row.index].date} />,
  
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Hata Mesajı' className='min-w-125px' />
    ),
    Cell: ({...props}) => <PayError error={props.data[props.row.index].error} />,
    accessor: 'error',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Provizyon No' className='min-w-125px' />
    ),
    accessor: 'prov_id',
  },
  //  {
  //    Header: (props) => (
  //      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //    ),
  //    id: 'actions',
  //    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  //  },
]

export {usersColumns}
